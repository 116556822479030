:root {
  --wl-text-color: #323E49;
  --wl-text-secondary-color: #495A6A;

  --wl-pure-white: #ffffff;
  --wl-base-white: #F4F4F4;

  --wl-primary-color: #09A3B0;

  --wl-primary-button-background-color: #09A3B0;
  --wl-primary-button-text-color: #ffffff;
  --wl-primary-button-border-radius: 20px;
  --wl-primary-button-box-shadow: none;

  --wl-secondary-button-background-color: #F8A91A;
  --wl-secondary-button-text-color: #323E49;
  --wl-secondary-button-border-radius: 20px;
  --wl-secondary-button-box-shadow: none;

  --wl-tab-text-color: #09A3B0;
  --wl-tab-background-color: #ffffff;
  --wl-tab-border-color: #09A3B0;

  --wl-tab-selected-text-color: #ffffff;
  --wl-tab-selected-background-color: #09A3B0;

  --wl-base-card-background-color: #F4F4F4;
  --wl-base-card-border-color: #BEBEBE;

  --wl-filter-card-background-color: #F4F4F4;
  --wl-filter-card-border-color: #495a6a;

  --wl-accordion-background-color: #F4F4F4;

  --wl-tag-background-color: #FFF;
  --wl-tag-border-color: #495a6a;
  --wl-tag-border-radius: 20px;

  --wl-input-text-color: #323E49;
  --wl-input-border-color: #E3E3E3;
  --wl-input-placeholder-color: #999FA4;
  --wl-border-color: #323E49;
  --wl-border-color-light: #BEBEBE;
  --wl-divider-color: #495A6A80;
  
  --wl-success-color: #48DF8D;
  --wl-success-color-dark: #2B641E;
  --wl-danger-color: #FF7B7B;
  --wl-danger-color-dark: #71192F;
}

h1,
h2,
h3,
h4,
p, 
li, 
span {
  color: var(--wl-text-color);
  margin: 0;
}

li {
  list-style: disc;
}

.btn-primary {
  --border-radius: var(--wl-primary-button-border-radius);
  --color: var(--wl-primary-button-text-color);
  --background: var(--wl-primary-button-background-color);
  --box-shadow: var(--wl-primary-button-box-shadow);
  height: 40px;
  text-transform: none;
}

.btn-secondary {
  --border-radius: var(--wl-secondary-button-border-radius);
  --color: var(--wl-secondary-button-text-color);
  --background: var(--wl-secondary-button-background-color);
  --box-shadow: var(--wl-secondary-button-box-shadow);
  text-transform: none;
}

.btn-outlined {
  --color: var(--wl-primary-color);
  border: 1px solid var(--wl-primary-color);
  border-radius: var(--wl-primary-button-border-radius);
  --background: transparent;
  --box-shadow: var(--wl-primary-button-box-shadow);
  text-transform: none;
  height: 40px;
}

.btn-icon-outlined {
  --color: var(--wl-primary-color);
  border: 1px solid var(--wl-primary-color);
  border-radius: var(--wl-primary-button-border-radius);
  --background: transparent;
  --box-shadow: var(--wl-primary-button-box-shadow);
  text-transform: none;
}

.btn-icon-ghost {
  --color: var(--wl-text-color);
  text-transform: none;
  --box-shadow: none;
  --background: transparent;
}

.btn-icon-primary {
  color: var(--wl-primary-color);
}

.btn-ghost {
  --color: var(--wl-text-color);
  text-transform: none;
  --box-shadow: none;
}

.btn-tab {
  --color: var(--wl-tab-text-color);
  --background: var(--wl-pure-white);
  --border-color: var(--wl-tab-border-color);
}

.btn-tab-selected {
  --color: var(--wl-tab-selected-text-color);
  --background: var(--wl-tab-selected-background-color);
}

.btn-tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.btn-tab,
.btn-tab-selected {
  --border-radius: 20px;
  --box-shadow: none;
  --border-width: 1px;
  --border-style: solid;
  height: 40px;
}

.btn-tag,
.btn-tag-selected {
  padding: 10px;
}

.btn-tag {
  color: var(--wl-text-color);
  background: none;
  border-radius: var(--wl-tag-border-radius);
  text-transform: none;
}

.btn-tag-selected {
  color: var(--wl-text-color);
  background-color: var(--wl-tag-background-color);
  border-radius: var(--wl-tag-border-radius);
}

.btn-full {
  width: 100%;
}

.xs-btn-full {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.text-color-primary {
  color: var(--wl-primary-button-text-color);
}

.text-color-secondary {
  color: var(--wl-secondary-button-background-color);
}

.wrapper {
  background-color: var(--wl-base-white);
  border-radius: 20px;
  padding: 10px;
  margin: 0 10px;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.divider-vertical {
  border: 1px solid var(--wl-divider-color);
  height: 33px;
}

.divider-horizontal {
  border: 0.5px solid var(--wl-divider-color);
  width: 100%;
}

.filter-card {
  background-color: var(--wl-filter-card-background-color);
  border-radius: 20px;

  padding: 16px;
}

.filter-card .filter-card-title {
  font-size: 12px;
  font-weight: 500;
  color: var(--wl-text-color);
  text-transform: uppercase;
}

.app-wrapper-lg {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 154px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 70px;
}

.dashboard-page-header-container {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  gap: 12px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    gap: 16px;
  }
}

.dashboard-page-header-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--wl-text-secondary-color);
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 26px;
    margin-bottom: 16px;
  }
}

.table-header-cell-item {
  font-size: 14px;
  color: var(--wl-text-color);
  font-weight: 700;
}

.table-body {
  font-size: 14px;
  color: var(--wl-text-color);
}

.table-cell-prime {
  background-color: var(--wl-pure-white);
}

.breadcrumb-text,
.breadcrumb-text-active {
  font-size: 16px;
  font-weight: 700;
  color: var(--wl-text-color);
}

.breadcrumb-text-active {
  color: var(--wl-primary-color);
}

.breadcrumb-icon {
  width: 10px;
  height: 10px;
}


.calendar-modal-trigger {
  border: 1px solid var(--wl-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  gap: 10px;
  padding: 10px 20px;
}

// style="border: 1px solid #323E49; padding-left: 10px; padding-right: 10px; background-color: #F4F4F4; width: 100%; display: flex; justify-content: space-between; align-items: center; height: 38px; border-radius: 20px;"
// Ionic Components

ion-content {
  position: unset;
  --background: white;
}

ion-input.input,
ion-textarea.input {
  background: var(--wl-pure-white);
  --placeholder-color: var(--wl-text-color);
  color: var(--wl-text-color);
  border-radius: 20px;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 20px;
  --padding-top: 10px;

  border: 2px solid var(--wl-input-border-color);
}

.modal-wrapper {
  background: var(--wl-base-white);
}

// ion-modal {
//   --width: fit-content;
//   --min-width: 250px;
//   --height: fit-content;
//   --border-radius: 10px;
// }

.checkbox {
  background: var(--wl-pure-white);
  border-radius: 8px;
  --checkmark-color: var(--wl-pure-white);
  --background-checked: var(--wl-primary-color);
  --border-color-checked: var(--wl-primary-color);
}

ion-radio {
  --color: var(--wl-primary-color);
  --color-checked: var(--wl-primary-color);
}

ion-toggle {
  --handle-background-checked: var(--wl-pure-white);
  --background-checked: var(--wl-primary-color);
}

// PrimeNG

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 10px !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--wl-primary-color);
}

.p-dropdown {
  border: 2px solid var(--wl-input-border-color);
  border-radius: 20px;
  padding-left: 10px;
  color: var(--wl-text-color);
  width: 100%;

  --placeholder-color: var(--wl-text-color);

  &:enabled:focus,
  &:enabled:active {
    border-color: var(--wl-primary-color) !important;
  }

  &:hover {
    border-color: var(--wl-primary-color) !important;
  }
}

.p-cascadeselect-items-wrapper {
  border: 2px solid var(--wl-input-border-color);
  border-radius: 20px;
  padding-left: 10px;
  color: var(--wl-text-color);
  width: 100%;
}

.p-cascadeselect-items-wrapper {
  border-radius: 20px;
}

.p-cascadeselect-item {
  list-style: none;
}

.p-dropdown:enabled:focus {
  border-color: var(--wl-primary-color) !important;
}
